import cx from 'classnames';
import { useRef, useState, useEffect } from 'react';
import { DesktopNavigationItem } from './DesktopNavigationItem';
import styles from './DesktopNavigation.module.scss';
import { useOutsideClick } from 'ui/hooks/useOutsideClick';
import { ServiceNavigation } from '../ServiceNavigation';

export type DesktopNavigationProps = UI.Navigation & {
	className?: string;
	toggleSearch?: () => void;
	isSearchPageIncluded?: boolean;
};

export const DesktopNavigation: React.FC<DesktopNavigationProps> = ({
	className,
	mainNavigation,
	serviceNavigation,
	currentPageId,
	toggleSearch,
	colorTheme = 'dark',
	isSearchPageIncluded,
}) => {
	const [currentNavId, setCurrentNavId] = useState(null);
	const navRef = useRef<HTMLElement>(null);

	let timeout = null;

	const showNavigation = (id) => {
		clearTimeout(timeout);
		setCurrentNavId(id);
	};

	const hideNavigation = (delay?: number) => {
		if (!delay) return setCurrentNavId(null);

		timeout = setTimeout(() => {
			setCurrentNavId(null);
		}, delay);
	};

	const handleClickOutside = () => {
		hideNavigation();
	};

	useEffect(() => {
		return () => clearTimeout(timeout);
	}, [timeout]);

	useOutsideClick(handleClickOutside, navRef);

	return (
		<>
			{serviceNavigation?.items && (
				<ServiceNavigation
					className={styles.DesktopNavigation_serviceNavigation}
					{...serviceNavigation}
					toggleSearch={toggleSearch}
					colorTheme={colorTheme}
					isSearchPageIncluded={isSearchPageIncluded}
				/>
			)}
			<nav
				className={cx(styles.DesktopNavigation, className)}
				ref={navRef}
				aria-label={mainNavigation?.ariaLabel}
			>
				<ul className={styles.DesktopNavigation_list}>
					{mainNavigation?.items &&
						mainNavigation?.items?.map((navItem) => {
							return (
								<DesktopNavigationItem
									key={navItem.id}
									currentNavId={currentNavId}
									showNavigation={showNavigation}
									hideNavigation={hideNavigation}
									currentPageId={currentPageId}
									previousButtonText={mainNavigation?.previousButtonText}
									previousButtonAriaLabel={mainNavigation?.previousButtonAriaLabel}
									{...navItem}
									colorTheme={colorTheme}
								/>
							);
						})}
				</ul>
			</nav>
		</>
	);
};
