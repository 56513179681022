import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useNavigationContext } from 'application/contexts/NavigationContext';
import { useSegmentContext } from 'application/contexts/SegmentContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import cx from 'classnames';
import { NewsSubscriptionFormFeature } from 'features/Modules';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { Layout } from 'ui/components/4-habitats/Layout';
import { FooterFeature } from '../Footer';
import { HeaderFeature } from '../Header';
import { Meta } from './Meta';
import { CookieConsent } from './Meta/CookieConsent';
import { HtmlBlock } from './Meta/HtmlBlock';
import { SearchMetadata } from './Meta/SearchMetadata';
import { Siteimprove } from './Meta/Siteimprove';

interface Props extends Content.PageContent {
	children: React.ReactNode;
}

export const LayoutFeature = ({
	children,
	themeSettings,
	page,
	footer,
	serviceNavigation,
	searchPageUrl,
	siteForms,
	searchMetadata,
}: Props): JSX.Element => {
	const siteContext = useSiteContext();
	const mediaBasePath = '/media/';
	const router = useRouter();
	const dictionary = useDictionaryContext();
	const { headHtml, bodyHtml, siteimproveId, cookieConsentGiven } = siteContext ?? {};
	const navContext = useNavigationContext();
	const formDictionary: UI.FormDictionary = {
		emailErrorMessage: dictionary.getValue('Form.EmailErrorMessage', null, 'Incorrect email format'),
		requiredErrorMessage: dictionary.getValue('Form.RequiredErrorMessage', null, 'This field is required'),
		selectErrorMessage: dictionary.getValue('Form.SelectErrorMessage', null, 'Please select an option'),
		multiSelectErrorMessage: dictionary.getValue(
			'Form.MultiSelectErrorMessage',
			null,
			'Please select at least one option',
		),
		loadingOptionsMessage: dictionary.getValue('Form.LoadingOptionsMessage', null, 'Loading options...'),
	};
	const { segment } = useSegmentContext();
	const segmentPages = useMemo(
		() =>
			navContext?.getSegmentPages({
				host: siteContext?.host,
				currentSegmentPath: segment,
			}) ?? [],
		[navContext, siteContext?.host, segment],
	);
	const mappedServiceNavigation = [...segmentPages, ...(serviceNavigation ?? [])];

	return (
		<div className={cx('page-wrapper', { [`theme__${themeSettings?.color}`]: themeSettings?.color })}>
			<Layout router={router} formDictionary={formDictionary} mediaBasePath={mediaBasePath}>
				<CookieConsent />
				<Meta {...page?.properties} favicon={themeSettings?.favicon} />
				<SearchMetadata metadata={searchMetadata} />
				{headHtml && <HtmlBlock headHtml={headHtml} bodyHtml={bodyHtml} />}
				<HeaderFeature
					themeSettings={themeSettings}
					serviceNavigation={mappedServiceNavigation}
					searchPageUrl={searchPageUrl}
				/>
				<noscript>
					<p>
						<em>
							{dictionary.getValue(
								'Accessibility.NoJavaScript.General',
								null,
								'Din browser understøtter ikke JavaScript hvilket kræves for, at alle denne hjemmesides komponenter fungerer optimalt.',
							)}
						</em>
					</p>
				</noscript>
				{children}
				{footer ? <FooterFeature footer={footer} themeSettings={themeSettings} /> : null}
				{siteForms?.map((form, index) => {
					if (!form) return null;

					if (form.documentType === 'newsSubscriptionForm') {
						return <NewsSubscriptionFormFeature {...form} key={index} />;
					}

					// Fallback to newsSubscriptionForm (we currently only have one type of form)
					return <NewsSubscriptionFormFeature {...form} key={index} />;
				})}
				<Siteimprove siteimproveId={siteimproveId} consentGiven={cookieConsentGiven} />
			</Layout>
		</div>
	);
};
