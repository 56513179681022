import cx from 'classnames';
import React from 'react';
import { Tag, TagProps } from 'ui/components/2-molecules/Tag';
import styles from './TagList.module.scss';

export interface TagListProps {
	className?: string;
	tags: TagProps[];
	layout?: 'column' | 'row';
}

export const TagList: React.FC<TagListProps> = ({ className, tags, layout = 'row' }) => {
	if (!tags || tags?.length === 0) return null;

	return (
		<ul className={cx(styles.TagList, styles[`TagList___${layout}`], className)}>
			{tags.map((tag, index) => {
				if (!tag?.children) return;

				return (
					<li key={index} className={cx(styles.TagList_item)}>
						<Tag {...tag} className={cx(styles.TagList_tag)} />
					</li>
				);
			})}
		</ul>
	);
};
