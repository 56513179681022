import cx from 'classnames';
import throttle from 'lodash.throttle';
import { useEffect, useRef, useState } from 'react';
import { RichText } from 'ui/components/1-atoms/RichText';
import styles from './ArticleTable.module.scss';

export interface ArticleTableProps {
	content: string;
	className?: string;
}

export const ArticleTable: React.FC<ArticleTableProps> = ({ className, content }) => {
	const [hasOverflowContent, setHasOverflowContent] = useState(false);
	const containerRef = useRef(null);

	const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
		const element = e.target as HTMLDivElement;
		const remainingScroll = element.scrollWidth - element.clientWidth - element.scrollLeft;

		if (remainingScroll <= 1) {
			setHasOverflowContent(false);
		} else {
			setHasOverflowContent(true);
		}
	};

	const resizeHandler = () => {
		if (containerRef.current.offsetWidth === containerRef.current.scrollWidth) {
			setHasOverflowContent(false);
		} else {
			scrollHandler({ target: containerRef.current } as React.UIEvent<HTMLDivElement>);
		}
	};

	const throttledScrollHandler = throttle(scrollHandler, 300);
	const throttledResizeHandler = throttle(resizeHandler, 300);

	useEffect(() => {
		setHasOverflowContent(containerRef.current.offsetWidth !== containerRef.current.scrollWidth);

		window.addEventListener('resize', throttledResizeHandler);

		return () => {
			window.removeEventListener('resize', throttledResizeHandler);
			throttledScrollHandler.cancel();
			throttledResizeHandler.cancel();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<RichText
			className={cx(
				styles.ArticleTable,
				{ [styles.ArticleTable___hasOverflowContent]: hasOverflowContent },
				className,
			)}
			content={content}
			onScroll={scrollHandler}
			forwardedRef={containerRef}
		/>
	);
};
