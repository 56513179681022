import { DATASETS_BASE_URL, DmpIncludeRelation } from 'application/adapters/dmp/dmpAdapter';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { getDmpDatasets } from 'application/repositories/dmp/dmpRepository';
import logger from 'helpers/logger';
import { spacingMapper } from 'helpers/mappers/spacingMapper';
import { widthMapper } from 'helpers/mappers/widthMapper';
import { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'ui/components/1-atoms/Media';
import { ArticleTable } from 'ui/components/2-molecules/Article';
import { Container } from 'ui/components/4-habitats/Container';

type DmpDatasetsDictionary = Record<Models.DmpDataset['id'], Models.DmpDataset>;
type UmbracoDmpDatasetAttribute =
	| 'title'
	| 'description'
	| 'supportContact'
	| 'metadata'
	| 'wms'
	| 'wfs'
	| 'wmts'
	| 'api'
	| 'file'
	| 'owner'
	| 'publisher'
	| 'productionSystem'
	| 'license'
	| 'link';

export const DmpDatasetSelectedDetailsFeature: React.FC<Content.DmpDatasetSelectedDetails> = ({ content }) => {
	const { datasetIds, richText, spacing, width } = content?.properties ?? {};
	const dictionary = useDictionaryContext();
	const mappedDatasetIds = useMemo(
		() => datasetIds?.map((item) => item?.content?.properties?.datasetId) ?? [],
		[datasetIds],
	);
	const [dmpData, setDmpData] = useState<DmpDatasetsDictionary>({});
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		if (!mappedDatasetIds || mappedDatasetIds.length === 0) return;

		setIsLoading(true);

		const relationships: DmpIncludeRelation[] = [
			'owners',
			'license',
			'productionSystem',
			'publisher',
			'tags',
			'wmsSource',
			'wfsSource',
			'wmtsSource',
			'apiSources',
			'fileSources',
		];

		getDmpDatasets({ ids: mappedDatasetIds, relationships }).then((data) => {
			const datasetAsDictionary = data.reduce((acc, dataset) => {
				acc[dataset.id] = dataset;
				return acc;
			}, {});

			setDmpData(datasetAsDictionary);
			setIsLoading(false);
		});
	}, [mappedDatasetIds]);

	interface MapAttributeParams {
		attributeName: UmbracoDmpDatasetAttribute;
		dataset: Models.DmpDataset;
	}

	const mapAttribute = ({ attributeName, dataset }: MapAttributeParams): string => {
		if (!attributeName) return;

		const attributeNameArray = attributeName.split('.');
		const attribute = attributeNameArray.shift()?.toLowerCase() as UmbracoDmpDatasetAttribute;
		// const properties = attributeNameArray; // Not used for now, leaving here as documentation for future use

		if (attribute === 'owner') {
			const owners = dataset.owners?.map((item) => {
				if (!item.name) return '';
				if (!item.url) return item.name;

				return `<a href="${item.url}" target="_blank" rel="noopener noreferrer">${item.name}</a>`;
			});

			return owners?.join('<br>') ?? '';
		}

		if (attribute === 'publisher') {
			const publisher = dataset.publishers?.find((item) => item.name);

			if (!publisher?.name) return '';
			if (!publisher?.url) return publisher.name;

			return `<a href="${publisher.url}" target="_blank" rel="noopener noreferrer">${publisher.name}</a>`;
		}

		if (attribute === 'productionSystem') {
			const productionSystem = dataset.productionSystems?.find((item) => item.name);
			return productionSystem?.name ?? '';
		}

		if (attribute === 'license') {
			const license = dataset.licenses?.find((item) => item.name);

			if (!license?.name) return '';
			if (!license?.url) return license.name;

			return `<a href="${license.url}" target="_blank" rel="noopener noreferrer">${license.name}</a>`;
		}

		if (attribute === 'wms' || attribute === 'wfs' || attribute === 'wmts') {
			const service = dataset.services[attribute as Models.Service]?.find((service) => service.url);

			if (!service) return '';

			const tooltipContent = dictionary.getValue(
				'DMP.CopyToClipboardConfirmation',
				null,
				'Kopieret til udklipsholderen',
			);
			const componentProps = {
				tooltip: {
					children: tooltipContent,
					fadeOut: true,
				},
			};
			return `<button target="_blank" rel="noopener noreferrer" data-component-tag="true" data-component-props='${JSON.stringify(
				componentProps,
			)}' data-clipboard-copy="${service.url}">${attribute.toUpperCase()}</button>`;
		}

		if (attribute === 'api' || attribute === 'file') {
			const service = dataset.services[attribute as Models.Service]?.find((service) => service.url);

			if (!service) return '';

			const linkTitle = `${dictionary.getValue('DMP.GoToLink', null, 'Gå til')} ${service.url}`;

			return `<a href="${
				service.url
			}" target="_blank" rel="noopener noreferrer" title="${linkTitle}" data-component-tag="true">${attribute.toUpperCase()}</a>`;
		}

		if (attribute === 'link') {
			if (!dataset.id || !dataset.title) return '';

			const href = `${DATASETS_BASE_URL}${dataset.id}`;

			return `<a href="${href}" target="_blank" rel="noopener noreferrer">Link</a>`;
		}

		if (attribute === 'metadata') {
			if (!dataset.metadata) return '';

			// Check if metadata is a valid URL, if so return it as a link. Otherwise return the value as is.
			try {
				const url = new URL(dataset.metadata);
				return `<a href="${url}" target="_blank" rel="noopener noreferrer">Metadata</a>`;
			} catch {
				return dataset.metadata;
			}
		}

		// For simple attributes like title, description, supportContact, metaData we can just return the value
		const attributeValue = dataset[attribute as keyof Models.DmpDataset];

		return attributeValue ? `${attributeValue}` : '';
	};

	const replaceRichTextAttributes = (richText: string, datasets: DmpDatasetsDictionary) => {
		if (!richText) return;

		// Regex to match {id.attributeName} or {id.attributeName.propertyName} where id is a number
		// and attributeName and propertyName are strings.
		// id is captured as group 1, attributeName and any additional property names are captured as group 2.
		// Supports multiple levels of nested properties such as {id.attributeName.propertyName.propertyName} etc.
		const regex = /\{(\d+)\.([a-zA-Z]+(?:\.[a-zA-Z]+)*)\}/g;

		return richText.replace(regex, (_, id: string, attributeName: UmbracoDmpDatasetAttribute): string => {
			if (!id || !attributeName) {
				logger.warn(`Invalid attribute format: {${id}.${attributeName}}`);
				return '';
			}

			const idAsNumber = Number(id);

			if (isNaN(idAsNumber)) {
				logger.warn(`Invalid id: ${id}`);
				return '';
			}

			const datasetId = mappedDatasetIds[idAsNumber - 1]; // Dataset IDs in this context are 1-indexed so we need to subtract 1

			if (!datasetId) {
				logger.warn(`Invalid dataset id: ${id}`);
				return '';
			}

			// Check if id exists in the dictionary
			if (datasets[datasetId]) {
				const mappedAttribute = mapAttribute({
					attributeName,
					dataset: datasets[datasetId],
				});

				if (mappedAttribute) return mappedAttribute;

				logger.warn(`Attribute named '${attributeName}' was not found for dataset with id '${id}'`);
				return '';
			}

			// Fallback for missing id
			logger.warn(`No dataset found for attribute with id '${id}'`);
			return '';
		});
	};

	return (
		<Container
			section
			width={width ? widthMapper(width) : 'lg'}
			spacingBottom={spacing ? spacingMapper(spacing) : 'sm'}
		>
			{isLoading ? <Spinner /> : <ArticleTable content={replaceRichTextAttributes(richText, dmpData)} />}
		</Container>
	);
};
