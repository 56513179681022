import { removeHttp } from 'helpers/string/string';
import { Link } from 'ui/components/1-atoms/Action/Link';
import { Caption } from 'ui/components/1-atoms/Caption';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Paragraph } from 'ui/components/1-atoms/Paragraph';
import { Time } from 'ui/components/1-atoms/Time';
import { TagList, TagListProps } from '../TagList';
import styles from './SearchResultItem.module.scss';

export interface SearchResultItemProps extends UI.PageItem {
	dateTime: Date;
	tags?: TagListProps['tags'];
}

export const SearchResultItem: React.FC<SearchResultItemProps> = ({
	heading,
	text,
	dateString,
	dateTime,
	link,
	tags,
}) => {
	return (
		<Link
			className={styles.SearchResultItem}
			url={link?.url}
			target={link?.target}
			rel={link?.target === '_blank' ? 'noopener noreferrer' : undefined}
		>
			{dateString && (
				<Caption renderParagraph className={styles.SearchResultItem_date}>
					<Time date={dateTime}>{dateString}</Time>
				</Caption>
			)}
			<div className={styles.SearchResultItem_wrapper}>
				<Heading className={styles.SearchResultItem_heading} headingLevel="h3" style="2xs">
					{heading}
				</Heading>
				{text && (
					<Paragraph className={styles.SearchResultItem_text}>
						<span dangerouslySetInnerHTML={{ __html: text }}></span>
					</Paragraph>
				)}
				{link.name && <Paragraph className={styles.SearchResultItem_link}>{removeHttp(link.name)}</Paragraph>}
				{tags?.length > 0 && <TagList className={styles.SearchResultItem_tags} tags={tags} />}
			</div>
		</Link>
	);
};
