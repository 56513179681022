import cx from 'classnames';
import throttle from 'lodash.throttle';
import React, { useEffect, useRef, useState } from 'react';
import { Heading } from 'ui/components/1-atoms/Heading';
import styles from './Table.module.scss';

type tableColumn = { content: string | React.ReactNode; columnSpan?: number };

export interface TableProps {
	className?: string;
	heading?: string;
	tableHead: tableColumn[];
	tableRows: { id?: string; columns: tableColumn[] }[];
}

export const Table: React.FC<TableProps> = ({ className, heading, tableHead, tableRows }) => {
	const [hasOverflowContent, setHasOverflowContent] = useState(false);
	const containerRef = useRef(null);

	const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
		const element = e.target as HTMLDivElement;
		const remainingScroll = element.scrollWidth - element.clientWidth - element.scrollLeft;

		if (remainingScroll <= 1) {
			setHasOverflowContent(false);
		} else {
			setHasOverflowContent(true);
		}
	};

	const resizeHandler = () => {
		if (containerRef.current.offsetWidth === containerRef.current.scrollWidth) {
			setHasOverflowContent(false);
		} else {
			scrollHandler({ target: containerRef.current } as React.UIEvent<HTMLDivElement>);
		}
	};

	const throttledScrollHandler = throttle(scrollHandler, 300);
	const throttledResizeHandler = throttle(resizeHandler, 300);

	useEffect(() => {
		setHasOverflowContent(containerRef.current.offsetWidth !== containerRef.current.scrollWidth);

		window.addEventListener('resize', throttledResizeHandler);

		return () => {
			window.removeEventListener('resize', throttledResizeHandler);
			throttledScrollHandler.cancel();
			throttledResizeHandler.cancel();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className={cx(styles.Table, { [styles.Table___hasOverflowContent]: hasOverflowContent }, className)}
			ref={containerRef}
			onScroll={scrollHandler}
		>
			{heading && (
				<Heading className={styles.Table_heading} headingLevel="h2" style="sm">
					{heading}
				</Heading>
			)}
			<table className={styles.Table_table}>
				<thead className={styles.Table_tableHead}>
					<tr>
						{tableHead.map((head, index) => {
							if (!head) return;

							const { content, columnSpan } = head;

							if (!content) return;

							return (
								<th key={index} colSpan={columnSpan || 1}>
									{content}
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody className={styles.Table_tableBody}>
					{tableRows.map((row, rowIndex) => {
						if (!row) return;

						const { id, columns } = row;

						if (!columns) return;

						return (
							<tr key={id ?? rowIndex}>
								{columns.map((column, columnIndex) => {
									if (!column) return;

									const { content, columnSpan } = column;

									if (!content) return;

									return (
										<td key={columnIndex} colSpan={columnSpan || 1}>
											{content}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};
