/*
	Adapters for DMP (Danmarks Miljøportal) API

	Documentation: https://datakatalog.miljoeportal.dk/api/swagger/index.html
*/

import logger from 'helpers/logger';

// Base URL for DMP API
const DMP_API_BASE_URL = 'https://datakatalog.miljoeportal.dk/api';

// Base URL for dataset links (used in features)
export const DATASETS_BASE_URL = 'https://arealdata.miljoeportal.dk/datasets/';

type DmpLocale = 'da-DK' | 'en-US';
type DmpEndpointId = string;
type DmpEndpoint =
	| 'datasets'
	| `datasets/${DmpEndpointId}`
	| 'datasetCollections'
	| `datasetCollections/${DmpEndpointId}`
	| `tags`;

export type DmpIncludeRelation =
	| 'owners'
	| 'license'
	| 'productionSystem'
	| 'publisher'
	| 'tags'
	| 'wmsSource'
	| 'wfsSource'
	| 'wmtsSource'
	| 'apiSources'
	| 'fileSources';

export type DmpIncludeDatasetRelation =
	| 'datasetCollectionItems.dataset'
	| 'datasetCollectionItems.dataset.owners'
	| 'datasetCollectionItems.dataset.license'
	| 'datasetCollectionItems.dataset.productionSystem'
	| 'datasetCollectionItems.dataset.publisher'
	| 'datasetCollectionItems.dataset.tags'
	| 'datasetCollectionItems.dataset.wmsSource'
	| 'datasetCollectionItems.dataset.wfsSource'
	| 'datasetCollectionItems.dataset.wmtsSource'
	| 'datasetCollectionItems.dataset.apiSources'
	| 'datasetCollectionItems.dataset.fileSources';

type GetDmpDataFromApiParams = {
	endpoint: DmpEndpoint;
	query?: string;
	locale?: DmpLocale;
};

// Generic DMP API request
export const getDmpDataFromApi = async ({
	endpoint,
	query,
	locale = 'da-DK',
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
GetDmpDataFromApiParams): Promise<any | any[]> => {
	let formattedQuery = query ? (query.startsWith('?') ? query : `?${query}`) : '';
	formattedQuery = formattedQuery ? `${formattedQuery}&locale=${locale}` : `?locale=${locale}`;
	const url = `${DMP_API_BASE_URL}/${endpoint}${formattedQuery}`;

	const response = await fetch(url);

	if (response.ok) {
		const data = await response.json();
		return data;
	}

	logger.error(`Failed to fetch DMP data at endpoint '/${endpoint}': ${response.status} - ${response.statusText}`);

	return {};
};

export const getDmpTags = async (tag?: string): Promise<DmpApiResponse.Tag[]> => {
	const endpoint = 'tags';
	let query = '';

	if (tag) {
		query = `?filter=equals(name,'${tag}')`;
	}

	const data = await getDmpDataFromApi({ endpoint, query });

	return data?.data ?? [];
};

type GetDmpDatasetParams = {
	id: string;
	include?: DmpIncludeRelation[];
};

type GetDmpDataCollectionParams = {
	id: string;
	include?: Array<DmpIncludeRelation | DmpIncludeDatasetRelation>;
};

export const getDmpDatasetFromApi = async ({ id, include }: GetDmpDatasetParams): Promise<DmpApiResponse.Dataset> => {
	const endpoint = `datasets/${id}` as DmpEndpoint;
	const query = include?.length > 0 ? `?include=${include.join(',')}` : '';

	return await getDmpDataFromApi({ endpoint, query });
};

type GetDmpDatasetsFromApiParams = {
	ids?: string[];
	include?: DmpIncludeRelation[];
	tag?: string;
};

export const getDmpDatasetsFromApi = async ({
	ids,
	include,
	tag,
}: GetDmpDatasetsFromApiParams): Promise<DmpApiResponse.Datasets> => {
	const endpoint = 'datasets';
	const searchParams = new URLSearchParams();

	if (ids?.length > 0) {
		const formattedIds = ids.map((id) => `'${id}'`).join(',');
		searchParams.append('filter', `and(any(id,${formattedIds}),not(equals(id,null)))`);
	}

	if (include?.length > 0) {
		searchParams.append('include', include.join(','));
	}

	if (tag) {
		const apiTags = await getDmpTags(tag);
		const tagId = apiTags?.find((apiTag) => apiTag?.attributes?.name === tag)?.id;

		if (!tagId) {
			logger.warn(`Tag '${tag}' not found`);

			return { data: [], included: [] };
		} else {
			searchParams.append('filter', `has(tags,equals(id,'${tagId}'))`);
		}
	}

	const query = searchParams.size > 0 ? `?${searchParams.toString()}` : '';

	return await getDmpDataFromApi({ endpoint, query });
};

export const getDmpDatasetCollectionFromApi = async ({
	id,
	include,
}: GetDmpDataCollectionParams): Promise<DmpApiResponse.DatasetCollection> => {
	const endpoint = `datasetCollections/${id}` as DmpEndpoint;
	const query = include?.length > 0 ? `?include=${include.join(',')}` : '';

	return await getDmpDataFromApi({ endpoint, query });
};
