import { DATASETS_BASE_URL } from 'application/adapters/dmp/dmpAdapter';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { getDmpDataCollection } from 'application/repositories/dmp/dmpRepository';
import { useEffect, useState } from 'react';
import { Link } from 'ui/components/1-atoms/Action';
import { Spinner } from 'ui/components/1-atoms/Media';
import { TagList } from 'ui/components/2-molecules/TagList';
import { Table } from 'ui/components/3-organisms/Table';
import { Container } from 'ui/components/4-habitats/Container';

export const DmpDataCollectionFeature: React.FC<Content.DmpDataCollection> = ({ content }) => {
	const { dataCollectionId } = content?.properties ?? {};
	const dictionary = useDictionaryContext();
	const [dmpData, setDmpData] = useState<Models.DmpDataCollection>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const sortedDmpData = dmpData?.datasets?.sort((a, b) => a.title.localeCompare(b.title)) ?? [];
	const heading = isLoading
		? dictionary.getValue('DMP.FetchingDataCollection', null, 'Henter datasamling...')
		: dmpData?.title ?? dictionary.getValue('DMP.DataCollection', null, 'Datasamling');
	const tableHead = [{ content: 'Titel' }, { content: 'Dataejer' }, { content: 'Tilgængelighed' }];
	const tableRows = sortedDmpData.map((dataset) => {
		if (!dataset) return;

		const { id, title, owners, services } = dataset;

		const titleContent = (
			<Link url={`${DATASETS_BASE_URL}${id}`} target="_blank" rel="noopener noreferrer" color="inherit">
				{title}
			</Link>
		);

		const mappedOwners = owners?.map((owner, index) => {
			const { id, name, url } = owner;

			if (!url) {
				return <div key={id ?? index}>{name}</div>;
			}

			return (
				<div key={id ?? index}>
					<Link key={id ?? index} url={url} target="_blank" rel="noopener noreferrer" color="inherit">
						{name}
					</Link>
				</div>
			);
		});

		const servicesAsArray = Object.entries(services ?? {})
			.map(([serviceType, serviceData]) => {
				const firstService = serviceData?.find((service) => service.url);

				if (!serviceType || !firstService) return;

				return { type: serviceType, data: firstService };
			})
			.filter((service) => service);

		const mappedServices = servicesAsArray.map(({ type, data }) => {
			if (!data) return;

			const { id, url } = data;

			if (!url) return;

			const shouldLink = type === 'file' || type === 'api';

			return {
				id,
				children: type.toUpperCase(),
				link: {
					url,
					target: '_blank',
					rel: 'noopener noreferrer',
					title: shouldLink
						? `${dictionary.getValue('DMP.GoToLink', null, 'Gå til')} ${url}`
						: dictionary.getValue('DMP.CopyUrlToClipboard', null, 'Kopier linket til udklipsholderen'),
				},
				clickHandler: shouldLink
					? undefined
					: (e: React.MouseEvent) => {
							e.preventDefault();
							navigator?.clipboard?.writeText(url); // Note that this only works in secure contexts (https)
					  },
				tooltip: shouldLink
					? undefined
					: {
							children: dictionary.getValue(
								'DMP.CopyToClipboardConfirmation',
								null,
								'Kopieret til udklipsholderen',
							),
							fadeOut: true,
					  },
			};
		});

		const servicesElement = <TagList tags={mappedServices} layout="row" />;

		return {
			id,
			columns: [{ content: titleContent }, { content: mappedOwners }, { content: servicesElement }],
		};
	});

	useEffect(() => {
		if (!dataCollectionId) return;

		setIsLoading(true);

		getDmpDataCollection(dataCollectionId).then((data) => {
			setDmpData(data);
			setIsLoading(false);
		});
	}, [dataCollectionId]);

	return (
		<Container width="md" section spacingBottom="md">
			{isLoading ? <Spinner /> : <Table heading={heading} tableHead={tableHead} tableRows={tableRows} />}
		</Container>
	);
};
