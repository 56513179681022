import { Container } from 'ui/components/4-habitats/Container';
import { BannerWide, BannerWideColorTheme } from 'ui/components/3-organisms/BannerWide';
import { spacingMapper } from 'helpers/mappers/spacingMapper';

export const BannerWideFeature: React.FC<Content.BannerWide> = ({ content }) => {
	const { colorTheme, image, spacing } = content?.properties ?? {};
	const colorThemeLowerCase = colorTheme?.toLowerCase() ?? 'light';

	return (
		<Container width="fluid" section spacingBottom={spacingMapper(spacing)}>
			<BannerWide
				{...content?.properties}
				image={{
					url: image?.url,
					altText: image?.alt,
				}}
				colorTheme={colorThemeLowerCase as BannerWideColorTheme}
			/>
		</Container>
	);
};
