import { NextRouter } from 'next/router';

type PushSearchFormParamsToUrlProps = {
	url: string;
	searchTerm: string;
	searchYearValue: string;
	searchMonthValue: string;
	topics: string[];
	page: string;
	router: NextRouter;
};

export const pushSearchFormParamsToUrl = ({
	url,
	searchTerm,
	searchYearValue,
	searchMonthValue,
	topics,
	page,
	router,
}: PushSearchFormParamsToUrlProps): void => {
	// Set URL params to reflect search
	const urlObject = new URL(url);
	const { searchParams } = urlObject;

	searchTerm ? searchParams.set('search', searchTerm) : searchParams.delete('search');
	searchYearValue ? searchParams.set('year', searchYearValue) : searchParams.delete('year');
	searchMonthValue ? searchParams.set('month', searchMonthValue) : searchParams.delete('month');
	page ? searchParams.set('page', page ?? '') : searchParams.delete('page');
	topics?.length > 0 ? searchParams.set('topics', topics.join(',')) : searchParams.delete('topics');

	if (searchParams.size === 0 && urlObject.hash) {
		return;
	}

	// Update URL without reloading page
	router.replace(urlObject.href, undefined, { shallow: true, scroll: false });
};
