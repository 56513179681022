import cx from 'classnames';
import React from 'react';
import styles from './LinkList.module.scss';
import { Link } from 'ui/components/1-atoms/Action/Link';

export interface LinkListProps {
	className?: string;
	links: UI.LinkListLink[];
	layout?: 'column' | 'row';
	size?: 'sm' | 'md';
	textDecoration?: boolean;
}

export const LinkList: React.FC<LinkListProps> = ({ className, links, layout = 'column', size, textDecoration }) => {
	if (!links || links?.length === 0) return null;

	return (
		<ul className={cx(styles.LinkList, styles[`LinkList___${layout}`], styles[`LinkList___${size}`], className)}>
			{links.map((link, index) => (
				<li
					key={index}
					className={cx(styles.LinkList_item, { [styles.LinkList_item___active]: link.isActive })}
				>
					<Link
						className={cx(
							styles.LinkList_link,
							{ [styles.LinkList_link___underline]: textDecoration },
							className,
						)}
						ariaLabel={link.ariaLabel || undefined}
						{...link}
					>
						{link.name}
					</Link>
				</li>
			))}
		</ul>
	);
};
