import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './Tooltip.module.scss';

export interface TooltipProps {
	className?: string;
	children: React.ReactNode | string;
	isActive?: boolean;
	fadeOut?: boolean; // If true, the tooltip will fade out after a certain time
	fadeOutTimer?: number; // Time in milliseconds before the tooltip fades out
	onFadeOutComplete?: () => void;
}

export const Tooltip: React.FC<TooltipProps> = ({
	className,
	children,
	isActive,
	fadeOut,
	fadeOutTimer = 3000,
	onFadeOutComplete,
}) => {
	const [isFadingOut, setIsFadingOut] = useState(false);
	const fadeDuration = 1000;

	useEffect(() => {
		if (!fadeOut || !isActive) return;

		const timer = setTimeout(() => {
			setIsFadingOut(true);
		}, fadeOutTimer);

		return () => {
			clearTimeout(timer);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isActive]);

	useEffect(() => {
		const timer = setTimeout(() => {
			onFadeOutComplete && onFadeOutComplete();
			setIsFadingOut(false);
		}, fadeDuration);

		return () => {
			clearTimeout(timer);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFadingOut]);

	return (
		<div
			className={cx(
				styles.Tooltip,
				isActive && styles.Tooltip___isActive,
				isFadingOut && styles.Tooltip___isFadingOut,
				className,
			)}
		>
			<div
				className={cx(styles.Tooltip_content)}
				style={{
					transitionDuration: `${fadeDuration}ms`,
				}}
			>
				{children}
			</div>
		</div>
	);
};
