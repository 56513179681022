import { DATASETS_BASE_URL } from 'application/adapters/dmp/dmpAdapter';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { getDmpDatasets } from 'application/repositories/dmp/dmpRepository';
import Fuse from 'fuse.js';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SearchResultView } from 'ui/components/3-organisms/SearchResultView';
import { Container } from 'ui/components/4-habitats/Container';

export const DmpSearchFeature: React.FC<Content.DmpSearch> = ({ content }) => {
	const { tag } = content?.properties ?? {};
	const formattedTag = tag?.toLowerCase().replace(' ', '-');
	const dictionary = useDictionaryContext();
	const cache = useRef<Record<string, Models.DmpDataset[]>>({});
	const searchFieldRef = useRef<HTMLInputElement>(null);
	const [dmpData, setDmpData] = useState<Models.DmpDataset[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [searchResults, setSearchResults] = useState<Models.DmpDataset[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchEntireCatalogue, setSearchEntireCatalogue] = useState<boolean>(false);
	const searchResultHeading = useMemo(() => {
		const searchResultHeadingEnding = searchEntireCatalogue
			? ` ${dictionary.getValue('DMP.Search.SearchEntireCatalogueEnding', null, 'i hele datakataloget')}`
			: '';

		if (isLoading) {
			return `${dictionary.getValue('DMP.Search.Searching', null, 'Søger')}${searchResultHeadingEnding}...`;
		}

		if (searchQuery && searchResults) {
			const fallback = `Din søgning på '${searchQuery}' gav ${
				searchResults?.length ?? 0
			} resultater${searchResultHeadingEnding}`;

			return dictionary
				.getValue('DMP.Search.QueryResults', null, fallback)
				.replace('${query}', searchQuery)
				.replace('${num}', `${searchResults?.length ?? 0}`);
		}

		const fallback = `Fandt ${searchResults?.length ?? 0} datasæt${searchResultHeadingEnding}`;

		return dictionary
			.getValue('DMP.Search.Results', null, fallback)
			.replace('${num}', `${searchResults?.length ?? 0}`);
	}, [dictionary, searchQuery, searchResults, searchEntireCatalogue, isLoading]);

	const debouncedSearch = useRef(
		debounce((value) => {
			setSearchQuery(value);
		}, 300),
	);

	// Fuse for fuzzy search
	const fuse = useMemo(() => {
		// Fuse options. See https://fusejs.io/api/options.html
		const fuseOptions = {
			includeScore: true,
			threshold: 0.4,
			minMatchCharLength: 3,
			keys: ['id', 'title', 'description', 'tags.name', 'owners.name'],
		};

		return new Fuse(dmpData, fuseOptions);
	}, [dmpData]);

	useEffect(() => {
		setIsLoading(true);

		const tag = searchEntireCatalogue ? undefined : formattedTag;

		// Check if we have the data in cache for the given tag
		if (cache.current[tag ?? 'default']) {
			setDmpData(cache.current[tag ?? 'default']);
			return;
		}

		getDmpDatasets({ tag }).then((data) => {
			setDmpData(data);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchEntireCatalogue]);

	useEffect(() => {
		if (!dmpData) return;

		setIsLoading(true);

		if (!searchQuery) {
			setSearchResults(dmpData);
			return;
		}

		const results = fuse.search(searchQuery);
		const mappedFuseResults = results.map((result) => result.item);
		setSearchResults(mappedFuseResults);
	}, [dmpData, searchQuery, fuse]);

	useEffect(() => {
		setIsLoading(false);
	}, [searchResults]);

	const mappedSearchResults = searchResults?.map((item) => {
		const servicesAsArray = Object.entries(item.services ?? {})
			.map(([serviceType, serviceData]) => {
				const firstService = serviceData?.find((service) => service.url);

				if (!serviceType || !firstService) return;

				return { type: serviceType, data: firstService };
			})
			.filter((service) => service);

		const mappedServices = servicesAsArray.map(({ type, data }) => {
			if (!data) return;

			const { id, url } = data;

			if (!url) return;

			const shouldOpen = type === 'file' || type === 'api';
			const linkTitle = shouldOpen
				? dictionary.getValue('DMP.GoToLink', null, 'Gå til') + ' ' + url
				: dictionary.getValue('DMP.CopyUrlToClipboard', null, 'Kopier linket til udklipsholderen');

			return {
				id,
				children: type.toUpperCase(),
				clickHandler: (e: React.MouseEvent) => {
					e.preventDefault();
					e.stopPropagation();

					if (shouldOpen) {
						window.open(url, '_blank', 'noopener noreferrer');
						return;
					}

					navigator?.clipboard?.writeText(url); // Note that this only works in secure contexts (https)
				},
				link: {
					url: null, // Render as a button since we nest the tag inside another link
					title: linkTitle,
				},
				tooltip: shouldOpen
					? undefined
					: {
							children: dictionary.getValue(
								'DMP.CopyToClipboardConfirmation',
								null,
								'Kopieret til udklipsholderen',
							),
							fadeOut: true,
					  },
			};
		});

		return {
			heading: item.title,
			text: item.description,
			dateString: undefined,
			dateTime: undefined,
			link: {
				name: undefined,
				url: `${DATASETS_BASE_URL}${item.id}`,
				target: '_blank',
			},
			tags: mappedServices,
		};
	});

	return (
		<Container width="md" section>
			<SearchResultView
				className="dmp-search"
				hero={undefined}
				filterProps={{
					heading: dictionary.getValue('Search.Filter.Heading', null, 'Søgning og filtrering'),
					searchField: {
						id: 'searchField',
						name: 'searchField',
						placeholder: dictionary.getValue('Search.Filter.SearchPlaceholder', null, 'Søg efter datasæt'),
						handleSearchStringChanged: (value) => debouncedSearch.current(value),
					},
					clearSearchBtn: {
						text: dictionary.getValue('Search.Filter.Clear', null, 'Nulstil søgning'),
						onClick: () => {
							setSearchQuery('');
							setSearchEntireCatalogue(false);
							searchFieldRef.current.value = '';
							searchFieldRef.current.focus();
						},
					},
					submitBtn: {
						text: 'Søg',
					},
					forwardedFormfieldStringRef: searchFieldRef,
					formFieldCheckboxes: [
						{
							labelText: dictionary.getValue(
								'DMP.Search.SearchEntireCatalogue',
								null,
								'Søg i hele datakataloget',
							),
							id: 'dmpSearchEntireCatalogue',
							name: 'dmpSearchEntireCatalogue',
							checked: searchEntireCatalogue,
							value: searchEntireCatalogue ? 'true' : 'false',
							onChange: (e) => setSearchEntireCatalogue(e.target.checked),
						},
					],
				}}
				resultListProps={{
					heading: searchResultHeading,
					isLoading,
					results: mappedSearchResults,
				}}
				pagination={{
					pageCount: 1,
					pageText: undefined,
					onPageChange: undefined,
				}}
				fallbackText={dictionary.getValue('Search.NoResults', null, 'Ingen søgeresultater fundet')}
			/>
		</Container>
	);
};
