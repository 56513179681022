import {
	DmpIncludeDatasetRelation,
	DmpIncludeRelation,
	getDmpDatasetCollectionFromApi,
	getDmpDatasetsFromApi,
} from 'application/adapters/dmp/dmpAdapter';
import { createIncludedDataDictionary, mapDatasets } from './helpers';

const defaultRelationships: DmpIncludeRelation[] = [
	'owners',
	'tags',
	'wmsSource',
	'wfsSource',
	'wmtsSource',
	'apiSources',
	'fileSources',
];

interface GetDmpDatasetsParams {
	ids?: string[];
	relationships?: DmpIncludeRelation[];
	tag?: string;
}

export const getDmpDatasets = async ({
	ids,
	relationships,
	tag,
}: GetDmpDatasetsParams): Promise<Models.DmpDataset[]> => {
	const include: DmpIncludeRelation[] = relationships ?? defaultRelationships;
	const data = await getDmpDatasetsFromApi({ ids, include, tag });
	const includedData = createIncludedDataDictionary(data.included);

	if (!data?.data) return [];

	const mappedDatasets = mapDatasets({ datasets: data.data, includedData });

	return mappedDatasets;
};

export const getDmpDataCollection = async (id: string): Promise<Models.DmpDataCollection> => {
	const include: DmpIncludeDatasetRelation[] = [
		'datasetCollectionItems.dataset',
		'datasetCollectionItems.dataset.owners',
		'datasetCollectionItems.dataset.tags',
		'datasetCollectionItems.dataset.wmsSource',
		'datasetCollectionItems.dataset.wfsSource',
		'datasetCollectionItems.dataset.wmtsSource',
		'datasetCollectionItems.dataset.apiSources',
		'datasetCollectionItems.dataset.fileSources',
	];

	const data = await getDmpDatasetCollectionFromApi({ id, include });
	const includedData = createIncludedDataDictionary(data.included);

	if (!data?.data) return;

	const { id: dataCollectionId, attributes } = data.data;
	const { title, description } = attributes;
	const datasets = includedData['datasets'] ? Object.values(includedData['datasets']) : [];
	const mappedDatasets = mapDatasets({ datasets, includedData });

	return {
		id: dataCollectionId,
		title,
		description,
		datasets: mappedDatasets,
	};
};
