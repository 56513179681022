import cx from 'classnames';
import React from 'react';
import { useState } from 'react';
import { Link } from 'ui/components/1-atoms/Action/Link';
import styles from './Tag.module.scss';
import { Tooltip, TooltipProps } from '../../1-atoms/Tooltip';

export interface TagProps extends UI.Tag {
	clickHandler?: (e: React.MouseEvent) => void;
	tooltip?: TooltipProps;
}

export const Tag: React.FC<TagProps> = ({ className, children, link, clickHandler, tooltip }) => {
	const [tooltipIsActive, setTooltipIsActive] = useState(false);
	const hasTooltip = tooltip?.children || tooltip?.children === '' ? true : false;

	const handleClick = (e: React.MouseEvent) => {
		if (clickHandler) {
			clickHandler(e);
		}

		if (hasTooltip) {
			setTooltipIsActive(!tooltipIsActive);
		}
	};

	const tooltipContent = hasTooltip ? (
		<Tooltip
			className={styles.Tag_tooltip}
			onFadeOutComplete={tooltip?.fadeOut ? () => setTooltipIsActive(false) : null}
			isActive={tooltipIsActive}
			{...tooltip}
		>
			{tooltip.children}
		</Tooltip>
	) : null;

	if (link?.url) {
		return (
			<Link
				{...link}
				className={cx(styles.Tag, tooltipIsActive && styles.Tag___isActive, className)}
				clickHandler={handleClick}
			>
				{children}
				{tooltipContent}
			</Link>
		);
	}

	if (hasTooltip || clickHandler) {
		return (
			<button
				className={cx(styles.Tag, tooltipIsActive && styles.Tag___isActive, className)}
				onClick={handleClick}
				title={link?.title}
			>
				{children}
				{tooltipContent}
			</button>
		);
	}

	return <div className={cx(styles.Tag, className)}>{children}</div>;
};
