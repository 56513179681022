import { useNavigationContext } from 'application/contexts/NavigationContext';
import { usePageContext } from 'application/contexts/PageContext';
import { useSegmentContext } from 'application/contexts/SegmentContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { getOpenGraphProps } from 'helpers/openGraph/openGraph';
import Head from 'next/head';
import { OpenGraph } from '../../../../ui/components/2-molecules/OpenGraph/OpenGraph';

export const Meta: React.FC<Content.PageContent['page']['properties']> = ({
	title,
	noFollow,
	noIndex,
	description,
	favicon,
}) => {
	const siteContext = useSiteContext();
	const pageContext = usePageContext();
	const { segment } = useSegmentContext();
	const navContext = useNavigationContext();
	const openGraph = pageContext?.getSettings('openGraph');
	const siteTitle = siteContext?.title;
	let pageTitle = title || pageContext.name || '';

	if (siteTitle) {
		pageTitle += ` - ${siteTitle}`;
	}

	const breadcrumbItems = navContext?.getBreadcrumb({
		host: siteContext?.host,
		currentUrl: pageContext.url,
		currentSegmentPath: segment,
		includeRootItem: true,
		includeSegmentRootPages: true,
		includeHidden: true,
	});
	const breadcrumbSchema = breadcrumbItems
		? {
				'@context': 'https://schema.org',
				'@type': 'BreadcrumbList',
				itemListElement: breadcrumbItems?.map((item, index) => {
					return {
						'@type': 'ListItem',
						position: index + 1,
						name: item.title && item.title !== '' ? item.title : `${item.name}`,
						item: item.url,
					};
				}),
		  }
		: null;

	const getFaviconFileType = (icon: string) => icon?.split('.')?.pop() ?? 'png';

	return (
		<>
			<Head>
				<title>{pageTitle}</title>
				<meta name="robots" content={`${noFollow ? 'nofollow' : 'follow'}, ${noIndex ? 'noindex' : 'index'}`} />
				<meta name="description" content={description ?? ''} />
				{favicon?.lightMode && (
					<link
						rel="icon"
						type={`image/${getFaviconFileType(favicon.lightMode)}`}
						href={favicon.lightMode}
						media="(prefers-color-scheme: light)"
					/>
				)}
				{favicon?.darkMode && (
					<link
						rel="icon"
						type={`image/${getFaviconFileType(favicon.darkMode)}`}
						href={favicon.darkMode}
						media="(prefers-color-scheme: dark)"
					/>
				)}
				{breadcrumbSchema && <script type="application/ld+json">{JSON.stringify(breadcrumbSchema)}</script>}
				<noscript
					dangerouslySetInnerHTML={{
						__html: `<style type="text/css">.hideIfNoScript { display:none !important; } noscript em {background-color: white;}</style>`,
					}}
				></noscript>
			</Head>
			<OpenGraph
				{...getOpenGraphProps(
					pageContext,
					openGraph,
					siteTitle ?? '',
					siteContext.fallbackOGImage,
					title,
					description,
				)}
			/>
		</>
	);
};
