import cx from 'classnames';
import React, { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'ui/components/1-atoms/Action/Link';
import IconArrow from 'ui/icons/icon-navigation_arrow.svg';
import styles from './SubNavigationItem.module.scss';
import { Icon } from 'ui/components/1-atoms/Media';
import { Subheading } from 'ui/components/1-atoms/Subheading';

export interface SubNavigationItemProps {
	itemHeader?: UI.NavigationItem;
	items: UI.NavigationItem[];
	className?: string;
	setCurrentSubNav: ({ id, navLevel }: { id: number; navLevel: number }) => void;
	currentSubNav: {
		id: number;
		navLevel: number;
	};
	parentId: number;
	navId: number;
	currentPageId: number;
	navLevel: number;
	isMobile?: boolean;
	setHeight: (height: number | 'auto') => void;
	closeMenu: () => void;
	previousButtonText?: string;
	previousButtonAriaLabel?: string;
}

export const SubNavigationItem: React.FC<SubNavigationItemProps> = ({
	itemHeader,
	items,
	className,
	setCurrentSubNav,
	currentSubNav,
	parentId,
	navId,
	currentPageId,
	navLevel,
	isMobile,
	setHeight,
	closeMenu,
	previousButtonText,
	previousButtonAriaLabel,
}) => {
	const ref = useRef(null);

	useEffect(() => {
		if (navId === currentPageId) {
			setCurrentSubNav({
				id: parentId,
				navLevel: navLevel === 0 ? navLevel : navLevel - 1,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (currentSubNav.id === navId) {
			setHeight(ref.current.scrollHeight);
		}
	}, [currentSubNav, navId, setHeight]);

	return (
		<>
			<CSSTransition
				in={currentSubNav.id === navId}
				timeout={{ appear: 0, exit: 300, enter: 300 }}
				appear={true}
				classNames={{ ...styles }}
				unmountOnExit
				nodeRef={ref}
			>
				<ul
					className={cx(
						styles.SubNavigationItem,
						{ [styles.SubNavigationItem___isMobile]: isMobile },
						className,
					)}
					style={{ transform: `translateX(${navLevel * 100}%)` }}
					ref={ref}
				>
					{itemHeader && navLevel > 0 && (
						<li className={styles.SubNavigationItem_item}>
							<button
								className={cx(styles.SubNavigationItem_prev, styles.SubNavigationItem_action)}
								onClick={() => setCurrentSubNav({ id: parentId, navLevel: navLevel - 1 })}
								aria-label={previousButtonAriaLabel || 'Back to parent'}
							>
								<Icon className={styles.SubNavigationItem_prevIcon}>
									<IconArrow />
								</Icon>
								<Subheading className={styles.SubNavigationItem_text} style="secondary">
									{previousButtonText || 'Back'}
								</Subheading>
							</button>
						</li>
					)}
					{itemHeader?.url && (
						<li className={cx(styles.SubNavigationItem_item, styles.SubNavigationItem_topLink)}>
							<Link
								title={itemHeader.title}
								url={itemHeader.url}
								className={cx(styles.SubNavigationItem_link, styles.SubNavigationItem_action)}
								clickHandler={closeMenu}
							>
								<span className={styles.SubNavigationItem_text}>{itemHeader.title}</span>
							</Link>
						</li>
					)}
					{items &&
						items.map((item: UI.NavigationItem, index: number) => {
							const hasChildren = item.children?.length ?? false;

							return (
								<li
									className={cx(
										styles.SubNavigationItem_item,
										{ [styles.isActive]: item.id === currentPageId },
										{ [styles.hasChildren]: hasChildren },
									)}
									key={index}
								>
									{hasChildren ? (
										<>
											<button
												onClick={() =>
													setCurrentSubNav({ id: item.id, navLevel: navLevel + 1 })
												}
												className={cx(
													styles.SubNavigationItem_next,
													styles.SubNavigationItem_action,
												)}
											>
												<span title={item.title} className={styles.SubNavigationItem_text}>
													{item.title}
												</span>
												<Icon className={styles.SubNavigationItem_nextIcon}>
													<IconArrow />
												</Icon>
											</button>
										</>
									) : (
										<Link
											clickHandler={() => closeMenu()}
											title={item.title}
											className={cx(
												styles.SubNavigationItem_link,
												styles.SubNavigationItem_action,
											)}
											url={item.url}
										>
											<span title={item.title} className={styles.SubNavigationItem_text}>
												{item.title}
											</span>
										</Link>
									)}
								</li>
							);
						})}
				</ul>
			</CSSTransition>
			{items &&
				items.map((item: UI.NavigationItem) => (
					<SubNavigationItem
						navLevel={navLevel + 1}
						key={item.id}
						navId={item.id}
						currentPageId={currentPageId}
						setCurrentSubNav={setCurrentSubNav}
						parentId={navId}
						items={item.children}
						itemHeader={item}
						currentSubNav={currentSubNav}
						isMobile={isMobile}
						setHeight={setHeight}
						closeMenu={closeMenu}
						previousButtonText={previousButtonText}
						previousButtonAriaLabel={previousButtonAriaLabel}
					/>
				))}
		</>
	);
};
