import logger from 'helpers/logger';

type IncludedDataDictionary = {
	datasets: Record<string, DmpApiResponse.DatasetData>;
	datasetCollectionItems: Record<string, DmpApiResponse.DatasetCollectionItemsIncludeItem>;
	organizations: Record<string, DmpApiResponse.Organization>;
	licenses: Record<string, DmpApiResponse.License>;
	productionSystems: Record<string, DmpApiResponse.ProductionSystem>;
	tags: Record<string, DmpApiResponse.Tag>;
	wmsSources: Record<string, DmpApiResponse.Source>;
	wfsSources: Record<string, DmpApiResponse.Source>;
	wmtsSources: Record<string, DmpApiResponse.Source>;
	apiSources: Record<string, DmpApiResponse.Source>;
	fileSources: Record<string, DmpApiResponse.Source>;
};

export const createIncludedDataDictionary = (
	includeData: Array<
		| DmpApiResponse.GenericIncludeItem
		| DmpApiResponse.DatasetData
		| DmpApiResponse.DatasetCollectionItemsIncludeItem
	>,
): IncludedDataDictionary => {
	if (!includeData) return {} as IncludedDataDictionary;

	const includedDataDictionary: IncludedDataDictionary = {
		datasets: {},
		datasetCollectionItems: {},
		organizations: {},
		licenses: {},
		productionSystems: {},
		tags: {},
		wmsSources: {},
		wfsSources: {},
		wmtsSources: {},
		apiSources: {},
		fileSources: {},
	};

	includeData.forEach((item) => {
		const { type, id } = item;

		if (!type || !id) return;

		if (!includedDataDictionary[type as DmpApiResponse.RelationshipDataType]) {
			includedDataDictionary[type as DmpApiResponse.RelationshipDataType] = {};
		}

		const existingData = includedDataDictionary[type as DmpApiResponse.RelationshipDataType][id];

		if (!existingData) {
			includedDataDictionary[type as DmpApiResponse.RelationshipDataType][id] = item;
		}
	});

	return includedDataDictionary;
};

interface MapDatasetParams {
	dataset: DmpApiResponse.DatasetData;
	includedData: IncludedDataDictionary;
}

interface MappedDatasetRelationships {
	tags: Models.DmpTag[];
	license: Models.DmpLicense[];
	productionSystem: Models.DmpProductionSystem[];
	owners: Models.DmpOwner[];
	publisher: Models.DmpPublisher[];
	wmsSource: Models.DmpService['wms'];
	wfsSource: Models.DmpService['wfs'];
	wmtsSource: Models.DmpService['wmts'];
	apiSources: Models.DmpService['api'];
	fileSources: Models.DmpService['file'];
}

export const mapDataset = ({ dataset, includedData }: MapDatasetParams): Models.DmpDataset => {
	const { id, attributes, relationships } = dataset;
	const { title, description, metadata, supportContact } = attributes;
	const mappedRelationships: MappedDatasetRelationships = {
		tags: [],
		owners: [],
		publisher: [],
		license: [],
		productionSystem: [],
		wmsSource: [],
		wfsSource: [],
		wmtsSource: [],
		apiSources: [],
		fileSources: [],
	};

	Object.entries(relationships).forEach(
		([relationshipType, relationshipData]: [
			DmpApiResponse.DatasetRelationshipDataType,
			DmpApiResponse.DatasetGenericRelationshipProps,
		]) => {
			const { data } = relationshipData;

			if (!data) return;

			const dataAsArray: DmpApiResponse.DatasetGenericRelationshipProps['data'][] = Array.isArray(data)
				? data
				: [data];
			const includedDataType: DmpApiResponse.DatasetRelationshipDataType = dataAsArray[0]?.type;

			if (!includedDataType) return;

			const mappedRelationshipData = dataAsArray.map((item) => {
				if (!item?.id) return;

				if (!includedData[includedDataType]) {
					logger.warn(`DMP mapDataset: No included data for ${includedDataType} found`);
					return;
				}

				// Get included data for the relationship
				const includedItem = includedData[includedDataType][item.id];

				// Map included data to a our desired format
				if (includedDataType === 'organizations') {
					const { id, attributes } = includedItem as DmpApiResponse.Organization;
					const { url, title, description } = attributes;

					return { id, url, name: title, description };
				}

				if (includedDataType === 'productionSystems') {
					const { id, attributes } = includedItem as DmpApiResponse.ProductionSystem;
					const { name, description } = attributes;

					return { id, name, description };
				}

				if (includedDataType === 'licenses') {
					const { id, attributes } = includedItem as DmpApiResponse.License;
					const { url, name } = attributes;

					return { id, url, name };
				}

				if (includedDataType === 'tags') {
					const { id, attributes } = includedItem as DmpApiResponse.Tag;
					const { name } = attributes;

					return { id, name };
				}

				if (
					includedDataType === 'wmsSources' ||
					includedDataType === 'wfsSources' ||
					includedDataType === 'wmtsSources' ||
					includedDataType === 'apiSources' ||
					includedDataType === 'fileSources'
				) {
					const { id, attributes } = includedItem as DmpApiResponse.Source;
					const { url } = attributes;

					return { id, url };
				}

				return includedItem;
			});

			// Add the mapped data to the corresponding relationship type in the mappedRelationships object
			mappedRelationships[relationshipType] = mappedRelationshipData;
		},
	);

	return {
		id,
		title,
		description,
		metadata,
		supportContact,
		tags: mappedRelationships.tags ?? [],
		owners: mappedRelationships.owners ?? [],
		publishers: mappedRelationships.publisher ?? [],
		productionSystems: mappedRelationships.productionSystem ?? [],
		licenses: mappedRelationships.license ?? [],
		services: {
			wms: mappedRelationships.wmsSource ?? [],
			wfs: mappedRelationships.wfsSource ?? [],
			wmts: mappedRelationships.wmtsSource ?? [],
			api: mappedRelationships.apiSources ?? [],
			file: mappedRelationships.fileSources ?? [],
		},
	};
};

interface MapDatasetsParams {
	datasets: DmpApiResponse.DatasetData[];
	includedData: IncludedDataDictionary;
}

export const mapDatasets = ({ datasets, includedData }: MapDatasetsParams): Models.DmpDataset[] => {
	return datasets.map((dataset) => {
		return mapDataset({ dataset, includedData });
	});
};
