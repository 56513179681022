import cx from 'classnames';
import { Heading } from 'ui/components/1-atoms/Heading';
import styles from './SearchResultWrapper.module.scss';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { Spinner } from 'ui/components/1-atoms/Media';

export interface SearchResultWrapperProps {
	className?: string;
	heading: string;
	isLoading: boolean;
	linkButton?: JSX.Element;
	filter?: React.ReactNode;
	children?: React.ReactNode;
}

export const SearchResultWrapper: React.FC<SearchResultWrapperProps> = ({
	className,
	heading,
	isLoading,
	linkButton,
	filter,
	children,
}) => {
	const dictionary = useDictionaryContext();
	return (
		<div className={cx(styles.SearchResultWrapper, className)}>
			<div className={styles.SearchResultWrapper_top_header}>
				<div className={styles.SearchResultWrapper_side___left}>
					{heading && (
						<Heading headingLevel={'h2'} style="xs">
							{heading}
						</Heading>
					)}
					{isLoading && (
						<div className={cx(styles.SearchResultWrapper_spinner, 'hideIfNoScript')}>
							<Spinner size={'small'} />
						</div>
					)}
				</div>
				{(linkButton || filter) && (
					<div className={styles.SearchResultWrapper_side___right}>
						{filter}
						{linkButton}
					</div>
				)}
			</div>
			<noscript>
				<p>
					<em>
						{dictionary.getValue(
							'Accessibility.NoJavaScript.Component',
							null,
							'Din browser understøtter ikke JavaScript hvilket kræves for dette komponent.',
						)}
					</em>
				</p>
			</noscript>
			{children && (
				<div
					className={cx(
						styles.SearchResultWrapper_results,
						isLoading && styles.SearchResultWrapper_results___isLoading,
					)}
				>
					{children}
				</div>
			)}
		</div>
	);
};
