import cx from 'classnames';
import React from 'react';
import { LinkButton } from 'ui/components/1-atoms/Action';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Picture } from 'ui/components/1-atoms/Media';
import { RichText } from 'ui/components/1-atoms/RichText';
import { Container, ContainerWidth } from 'ui/components/4-habitats/Container';
import styles from './BannerWide.module.scss';

export type BannerWideColorTheme = 'light' | 'dark';

export interface BannerWideProps {
	className?: string;
	heading: string;
	text?: string;
	links?: UI.LinkBaseProps[];
	image: UI.Image;
	imagePosition: 'left' | 'right';
	colorTheme: BannerWideColorTheme;
	width?: ContainerWidth;
}

export const BannerWide: React.FC<BannerWideProps> = ({
	className,
	heading,
	text,
	links,
	image,
	imagePosition = 'left',
	colorTheme = 'dark',
	width,
}) => {
	const firstButtonStyle = colorTheme === 'dark' ? 'primary-negative' : 'primary';
	const secondButtonStyle = colorTheme === 'dark' ? 'secondary-negative' : 'secondary';

	return (
		<div
			className={cx(
				styles.BannerWide,
				styles[`BannerWide___image-${imagePosition}`],
				styles[`BannerWide___${colorTheme}`],
				className,
			)}
		>
			<Container className={styles.BannerWide_inner} width={width}>
				<div className={styles.BannerWide_imageContainer}>
					{image?.url && (
						<Picture className={styles.BannerWide_image} url={image.url} altText={image.altText} isCover />
					)}
				</div>
				<div className={styles.BannerWide_content}>
					<Heading className={styles.BannerWide_heading} headingLevel="h2" style="lg">
						{heading}
					</Heading>
					{text && <RichText className={styles.BannerWide_text} content={text} />}
					{links && (
						<div className={styles.BannerWide_buttons}>
							{links.map((link, index) => (
								<LinkButton
									key={index}
									className={styles.BannerWide_button}
									{...link}
									style={index === 0 ? firstButtonStyle : secondButtonStyle}
								>
									{link.name}
								</LinkButton>
							))}
						</div>
					)}
				</div>
			</Container>
		</div>
	);
};
