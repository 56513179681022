import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useNavigationContext } from 'application/contexts/NavigationContext';
import { PageContext } from 'application/contexts/PageContext';
import { useSegmentContext } from 'application/contexts/SegmentContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { useContext } from 'react';
import { Breadcrumb } from 'ui/components/2-molecules/Navigation/Breadcrumb';

export const BreadcrumbFeature: React.FC = () => {
	const navContext = useNavigationContext();
	const siteContext = useSiteContext();
	const pageContext = useContext(PageContext);
	const { segment } = useSegmentContext();
	const { themeSettings } = pageContext;
	const dictionary = useDictionaryContext();

	const breadcrumbItems = navContext?.getBreadcrumb({
		host: siteContext?.host,
		currentUrl: pageContext.url,
		currentSegmentPath: segment,
		includeRootItem: true,
		includeSegmentRootPages: true,
		includeHidden: true,
	});

	if (!breadcrumbItems?.length) return null;

	return (
		<Breadcrumb
			breadCrumbLabel={dictionary.getValue('Breadcrumb', null, 'Breadcrumb')}
			breadcrumbItems={breadcrumbItems.map((item) => {
				return {
					...item,
					title: item.title && item.title !== '' ? item.title : `${item.name}`,
				};
			})}
			theme={themeSettings?.heroAndBreadcrumbColorTheme}
		/>
	);
};
