import Script from 'next/script';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/dist/client/router';

export type SiteimproveWindow = Window & typeof globalThis & { _sz: unknown[] };

export interface SiteImproveProps {
	siteimproveId: string;
	consentGiven: boolean;
}

export const Siteimprove: React.FC<SiteImproveProps> = ({ siteimproveId, consentGiven }) => {
	const router = useRouter();
	const [previousUrl, setPreviousUrl] = useState(router.asPath);

	useEffect(() => {
		if (!consentGiven) return;

		const sz = (window as SiteimproveWindow)._sz;
		if (!siteimproveId || !sz) return;

		sz.push([
			'trackdynamic',
			{
				url: new URL(router.asPath, location.origin),
				ref: new URL(previousUrl, location.origin),
				title: document.title,
			},
		]);

		setPreviousUrl(router.asPath);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [siteimproveId, router.asPath, consentGiven]);

	if (!siteimproveId || !consentGiven) return null;

	return (
		<Script
			id="Siteimprove"
			data-cookieconsent="statistics"
			strategy="lazyOnload"
			async
			src={`//ssl.siteimprove.com/js/siteanalyze_${siteimproveId}.js`}
		/>
	);
};
