import { useEffect } from 'react';
import { useSiteContext } from 'application/contexts/SiteContext';
import Script from 'next/script';
import logger from 'helpers/logger';

export const CookieConsent: React.FC = () => {
	const siteContext = useSiteContext();

	useEffect(() => {
		// Check if the user has given consent to statistics cookies
		const checkCookieConsent = () => {
			const cookies = document.cookie.split('; ');
			const consentCookie = cookies.find((cookie) => cookie.startsWith('CookieConsent='));
			if (consentCookie) {
				try {
					const decodedValue = decodeURIComponent(consentCookie);

					const consentGroups = decodedValue.split(',').reduce((acc, group) => {
						const [key, value] = group.split(':');
						acc[key] = value === 'true';
						return acc;
					}, {} as Record<string, boolean>);
					if (consentGroups.statistics) {
						siteContext.setCookieConsentGiven?.(true);
					}
				} catch (error) {
					logger.error('CookieConsent: Error parsing cookie', error);
				}
			}
		};

		checkCookieConsent();

		const handleConsent = () => {
			checkCookieConsent();
		};

		window.addEventListener('CookieConsent', handleConsent);

		return () => {
			window.removeEventListener('CookieConsent', handleConsent);
		};
	}, [siteContext]);

	if (!siteContext?.cookiebotId) return null;

	return (
		<Script
			id="Cookiebot"
			src="https://consent.cookiebot.com/uc.js"
			data-cbid={siteContext.cookiebotId}
			data-blockingmode="auto"
			type="text/javascript"
			strategy="afterInteractive"
			data-culture={siteContext.culture}
			async
		/>
	);
};
