import { FormContextProvider } from 'ui/contexts/FormContext';
import { RouteContextProvider } from 'ui/contexts/RouteContext';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Layout = ({ children, router, formDictionary, mediaBasePath }) => {
	const formDictionaryDefault: UI.FormDictionary = {
		emailErrorMessage: 'Incorrect email format',
		requiredErrorMessage: 'This field is required',
		selectErrorMessage: 'Please select an option',
		multiSelectErrorMessage: 'Please select at least one option',
		loadingOptionsMessage: 'Loading options...',
	};

	return (
		<RouteContextProvider router={router} mediaBasePath={mediaBasePath}>
			<FormContextProvider dictionary={formDictionary ?? formDictionaryDefault}>{children}</FormContextProvider>
		</RouteContextProvider>
	);
};
