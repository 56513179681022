import { useRouter } from 'next/router';
import { Embed } from 'ui/components/2-molecules/Embed';

export const EmbedFeature: React.FC<Content.EmbedCard> = ({ content }) => {
	const { properties } = content;
	const router = useRouter();

	// Use router.asPath as a key to force re-rendering when the route changes
	return <Embed {...properties} key={router.asPath} />;
};
